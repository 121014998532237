import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout.js'
import Toc from '../components/toc'

export default props => {
    const { course } = props.data

    const handleClick = () => {
        window.nonExistingFunc()
    }
    return (
        <Layout dontFixHeader={true}>
            <div className="bg-gray-50">
                <Toc
                    active={course.childrenEpisode[0]}
                    course={course}
                    episodes={course.childrenEpisode}
                    autoplay={false}
                    setAutoplay={v => {}}
                />
            </div>
        </Layout>
    )
}
export const query = graphql`
    query {
        course(id: { eq: "lets-clone-unitied-game" }) {
            id
            title
            sku
            price
            description
            releaseDate
            isFree
            difficulty
            videoDuration
            episodeCount
            vimeoTrailerId
            youtubeTrailerId
            childrenEpisode {
                isFree
                slug
                title
            }
            bannerImg {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`
